import api from "@/utils/axios";
import identityApi from "@utils/axiosIdentity";
import router from "@/router";
import { useToast } from "vue-toastification";
import { i18n } from "@/libs/i18n";

const toast = useToast();

const state = {
  users: [],
  userPermissions: [],
  pagination: {
    totalObjects: 0,
    perPage: 5,
    currentPage: 1,
  },
  userSearchString: null,
  user: {},
  accountType: "",
  passwordPolicies: "",
  isLoading: false,
};

const getters = {
  users(state) {
    return state.users;
  },
  userPermissions(state) {
    return state.userPermissions;
  },
  user(state) {
    return state.user;
  },
  totalUsers(state) {
    return state.pagination.total;
  },
};

const mutations = {
  ADD_USER_FORM_TO_TABLE(state) {
    if (!state.users[0].toBeCreated) {
      state.users.unshift({
        name: "",
        email: "",
        username: "",
        active_status: true,
        loggin_attempts: 0,
        toBeCreated: true,
      });
    }
  },
  CANCEL_ADD_USER_FORM_TO_TABLE(state) {
    if (state.users[0].toBeCreated) {
      state.users.shift();
    }
  },
  EDIT_NEW_USER(state, { field, value }) {
    if (state.users[0].toBeCreated) {
      state.users[0][field] = value;
    }
  },
  SET_USERS(state, payload) {
    state.users = payload;
  },
  SET_USER_PERMISSIONS(state, payload) {
    state.userPermissions = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_USER_FIELD(state, { field, value }) {
    state.user[field] = value;
  },
  SET_PAGINATION(state, { field, value }) {
    state.pagination[field] = value;
  },
  SET_USER_SEARCH_STRING(state, value) {
    state.userSearchString = value;
  },
  TOGGLE_USER_STATUS(state, { user, status }) {
    // state.users[userIndex].active = status;
    user.active = status;
  },
  SET_ACCOUNT_TYPE(state, type) {
    state.accountType = type;
  },
  SET_PASSWORD_POLICIES(state, policies) {
    state.passwordPolicies = policies;
  },
  CANCEL_USER_GENERAL_INFO(state) {
    router.back();
  },
  SET_LOADING(state, value) {
    state.isLoading = value;
  },
};

const actions = {
  getUsers(context, { filterByName = null, orderBy = null }) {
    identityApi
      .get("/v1/users", {
        params: {
          PerPage: context.state.pagination.perPage,
          CurrentPage: context.state.pagination.currentPage,
          FilterByName: filterByName,
          OrderBy: orderBy,
        },
      })
      .then((response) => {
        context.commit("SET_USERS", response.data.results);
        context.commit("SET_PAGINATION", {
          field: "totalObjects",
          value: response.data.totalObjects,
        });
        context.commit("SET_PAGINATION", {
          field: "currentPage",
          value: response.data.currentPage,
        });
      });
  },

  getUser(context, { id }) {
    return new Promise((resolve, reject) => {
      identityApi
        .get(`/v1/users/${id}`)
        .then((response) => {
          context.commit("SET_USER", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createUser(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post("/users", payload)
        .then((response) => {
          // context.commit("SET_USER", response.data);
          toast.success(i18n.global.t("successMessages.userCreated"));
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateUser(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post("/users/update", payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getUserAccountType(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .get("/users/get_account_type")
        .then((response) => {
          context.commit("SET_ACCOUNT_TYPE", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getUserAdminPriviligies(context, { userId, applicationId }) {
    return new Promise((resolve, reject) => {
      api
        .get(`/users/${userId}/get_admin_privileges`, {
          params: {
            ApplicationId: applicationId,
          },
        })
        .then((response) => {
          context.commit("SET_USER_FIELD", {
            field: "isAdmin",
            value: response.data,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  toggleUserAdminPriviligies(context, { userId, applicationId }) {
    return new Promise((resolve, reject) => {
      api
        .post(
          `/users/${userId}/toggle_admin_privileges`,
          {},
          {
            params: {
              ApplicationId: applicationId,
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getPasswordPolicies(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .get("/users/password_policies")
        .then((response) => {
          context.commit("SET_PASSWORD_POLICIES", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  toggleUserStatus(context, { id, status }) {
    return new Promise((resolve, reject) => {
      api
        .post(
          `/users/${id}/toggle_active_status`,
          {},
          {
            params: {
              activeStatus: status,
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changeUserPassword(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post("/users/change_password", payload)
        .then((response) => {
          toast.success(i18n.global.t("successMessages.passwordUpdated"));
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  resetLogginAttempts(context, { userId }) {
    return new Promise((resolve, reject) => {
      api
        .post(`/users/${userId}/reset_login_attempts`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getUserPermissions(context, { applicationId, userId }) {
    context.commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      api
        .get("/users/get_permission_detail", {
          params: {
            ApplicationId: applicationId,
            UserId: userId,
          },
        })
        .then((response) => {
          context.commit("SET_USER_PERMISSIONS", response.data);
          resolve(response);
        })
        .catch((error) => {
          context.commit("SET_USER_PERMISSIONS", []);
          reject(error);
        })
        .finally(() => {
          context.commit("SET_LOADING", false);
        });
    });
  },

  saveUserPermissions(context, { userPermissions }) {
    return new Promise((resolve, reject) => {
      api
        .post("/users/save_user_permission", userPermissions)
        .then((response) => {
          toast.success(i18n.global.t("successMessages.permissionsSaved"));
          context.commit("SET_USER_PERMISSIONS", response.data);
          resolve(response);
        })
        .catch((error) => {
          toast.error(error.response.data);
          reject(error);
        });
    });
  },

  getAccessUser(_, { FilterString }) {
    return new Promise((resolve, reject) => {
      api
        .get(`/users`, {
          params: {
            FilterString,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  unassignUserAllApplications(_, { id }) {
    return new Promise((resolve, reject) => {
      identityApi
        .delete(`/v1/users/${id}/unassign_user_from_all_applications`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createNewUser(_, { payload }) {
    return new Promise((resolve, reject) => {
      api
        .post(`/users`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
