import { useToast } from "vue-toastification";
import { i18n } from "@/libs/i18n";

const toast = useToast();

let REQUEST_COUNT = 0;
// Número máximo de peticiones permitidas en el intervalo
const MAX_REQUEST = 5;

// Intervalo de tiempo para restablecer el contador en milisegundos
const INTERVAL_REQUESTS = 6000;
let timer = null;

function showErrors(errors) {
  for (let i = 0; i < errors.length; i += 1) {
    toast.error(errors[i].toString())
  }
}

const baseInterceptor = instance => {
  instance.interceptors.request.use(config => {
    const currentUser = JSON.parse(sessionStorage.getItem('userData'))
    if (currentUser) {
      config.headers.Authorization = `Bearer ${currentUser.access_token}`
    }

    return config
  })

  instance.interceptors.response.use(
    response => {
      if ((response.config.method === 'post' || response.config.method === 'put') && (response.status === 200 || response.status === 201)) {
        toast.success(response.data.message || 'Acción exitosa!')
      }
      return response
    },
    async error => {
      if (error.response.status === 401) {
        REQUEST_COUNT++;
          
        if (!timer) {
          timer = setTimeout(() => {
            REQUEST_COUNT = 0;
            clearTimeout(timer);
            timer = null;
          }, INTERVAL_REQUESTS);
        }

        // Abortar peticiones si se rebasa el límite para evitar recursividad
        if (REQUEST_COUNT >= MAX_REQUEST) {
          toast.error(i18n.global.t('errorMessages.limitRequest'))
          return Promise.reject(new Error('Limit of request'))
        }
      }

      if (error.response.data) {
        if (error.response.data.Message || error.response.data.message) {
          toast.error(error.response.data.Message || error.response.data.message)
        }
        if (error.response.data.errors) {
          const errors = Object.values(error.response.data.errors)
          showErrors(errors)
        } else if (error.response.data.Errors) {
          const errors = Object.values(error.response.data.Errors)
          showErrors(errors)
        }
      }
      return Promise.reject(error)
    },
  )
}

export default baseInterceptor
