import api from "@/utils/axios";

const state = () => ({});

const getters = {};

const mutations = {};

const actions = {
  async getLicences(_, { applicationId }) {
    try {
      const response = await api.get("/licenses", {
        params: {
          applicationId,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async createNewLicences(_, { data }) {
    try {
      const response = await api.post("/licenses", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
