export default {
  beforeMount(el, binding) {
    let tooltipText = binding.value || "";
    let delay = binding.arg ? parseInt(binding.arg) : 300;

    // Determinar la posición con múltiples modificadores
    let position = { vertical: "top", horizontal: "center" };

    if (binding.modifiers.bottom) position.vertical = "bottom";
    if (binding.modifiers.top) position.vertical = "top";
    if (binding.modifiers.left) position.horizontal = "left";
    if (binding.modifiers.right) position.horizontal = "right";

    // Crear el tooltip
    const tooltip = document.createElement("div");
    tooltip.className = `custom-tooltip ${position.vertical} ${position.horizontal}`;
    tooltip.innerText = tooltipText;
    document.body.appendChild(tooltip);

    let showTimeout;

    const showTooltip = (event) => {
      showTimeout = setTimeout(() => {
        const rect = el.getBoundingClientRect();
        let left, top;

        // Posicionamiento dinámico
        if (position.vertical === "bottom") {
          top = rect.bottom + 10;
        } else {
          top = rect.top - tooltip.offsetHeight - 10;
        }

        if (position.horizontal === "left") {
          left = rect.left;
        } else if (position.horizontal === "right") {
          left = rect.right - tooltip.offsetWidth;
        } else {
          left = rect.left + (rect.width - tooltip.offsetWidth) / 2;
        }

        tooltip.style.left = `${left}px`;
        tooltip.style.top = `${top}px`;
        tooltip.classList.add("visible");
      }, delay);
    };

    const hideTooltip = () => {
      clearTimeout(showTimeout);
      tooltip.classList.remove("visible");
    };

    el.__tooltipElement__ = tooltip;
    el.__showTooltip__ = showTooltip;
    el.__hideTooltip__ = hideTooltip;

    el.addEventListener("mouseenter", showTooltip);
    el.addEventListener("mouseleave", hideTooltip);
  },

  unmounted(el) {
    if (el.__tooltipElement__) {
      document.body.removeChild(el.__tooltipElement__);
    }
    el.removeEventListener("mouseenter", el.__showTooltip__);
    el.removeEventListener("mouseleave", el.__hideTooltip__);
  },
};
