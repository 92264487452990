export const getEnvVariable = (key, defaultValue) => {
  return window._env_ && window._env_[key] ? window._env_[key] : defaultValue;
};

// Aplicar estilos a un único elemento del DOM identificado por un selector CSS.
export const applyStyles = (selector, styles) => {
  const element = document.querySelector(selector);
  if (element) {
    Object.assign(element.style, styles);
  }
};

// Aplicar estilos específicos a la primera columna y el primer encabezado de una tabla para que se comporten como "sticky".
export const stickyColumnTable = () => {
  const applyStickyStyles = (elements, zIndex) => {
    elements.forEach((element) => {
      element.style.position = "sticky";
      element.style.left = "0";
      element.style.background = "#2b2b2c";
      element.style.zIndex = zIndex;
      element.style.border = "2px solid";
    });
  };

  const firstThs = document.querySelectorAll("thead tr th:first-child");
  if (firstThs.length > 0) {
    applyStickyStyles(firstThs, "3");
  }

  const observer = new MutationObserver(() => {
    const firstColumnCells = document.querySelectorAll(
      "tbody tr td:first-child"
    );
    if (firstColumnCells.length > 0) {
      applyStickyStyles(firstColumnCells, "2");
    }
  });

  const tableBody = document.querySelector("tbody");
  if (tableBody) {
    observer.observe(tableBody, { childList: true, subtree: true });
  }
};
