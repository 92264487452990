import { UserManager, WebStorageStateStore } from 'oidc-client'
import useJwt from '@/auth/jwt/useJwt'
import {
  ApplicationName,
  AuthorizationPaths,
} from './api-authorization-constants'
import { getEnvVariable } from '@/helpers'

export class AuthorizeService {
  _callbacks = [];

  _nextSubscriptionId = 0;

  _user = null;

  _isAuthenticated = false;

  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.
  _popUpDisabled = true;

  async isAuthenticated() {
    const user = await this.getUser()
    return !!user
  }

  async getUser() {
    if (this._user && this._user.profile) {
      return this._user.profile
    }

    await this.ensureUserManagerInitialized()
    const user = await this.userManager.getUser()
    return user && user.profile
  }

  async getAccessToken() {
    await this.ensureUserManagerInitialized()
    const user = await this.userManager.getUser()
    return user && user.access_token
  }

  // We try to authenticate the user in three different ways:
  // 1) We try to see if we can authenticate the user silently. This happens
  //    when the user is already logged in on the IdP and is done using a hidden iframe
  //    on the client.
  // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
  //    redirect flow.
  async signIn(state) {
    await this.ensureUserManagerInitialized()
    try {
      const silentUser = await this.userManager.signinSilent(
        this.createArguments(),
      )
      this.updateState(silentUser)
      return this.success(state)
    } catch (silentError) {
      // User might not be authenticated, fallback to popup authentication

      try {
        if (this._popUpDisabled) {
          throw new Error(
            "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it.",
          )
        }

        const popUpUser = await this.userManager.signinPopup(
          this.createArguments(),
        )
        this.updateState(popUpUser)
        return this.success(state)
      } catch (popUpError) {
        if (popUpError.message === 'Popup window closed') {
          // The user explicitly cancelled the login action by closing an opened popup.
          return this.error('The user closed the window.')
        } if (!this._popUpDisabled) {
        }

        // PopUps might be blocked by the user, fallback to redirect
        try {
          await this.userManager.signinRedirect(this.createArguments(state))
          return this.redirect()
        } catch (redirectError) {
          return this.error(redirectError)
        }
      }
    }
  }

  async completeSignIn(url) {
    try {
      await this.ensureUserManagerInitialized()
      const user = await this.userManager.signinCallback(url)
      this.updateState(user)
      return this.success(user && user.state)
    } catch (error) {
      return this.error('There was an error signing in.')
    }
  }

  // We try to sign out the user in two different ways:
  // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
  //    post logout redirect flow.
  async signOut(state) {
    localStorage.clear();
    sessionStorage.removeItem('accessToken')
    sessionStorage.removeItem('refreshToken')
    await this.ensureUserManagerInitialized();
    try {
      if (this._popUpDisabled) {
        throw new Error(
          "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it.",
        )
      }

      await this.userManager.signoutPopup(this.createArguments())
      this.updateState(undefined)
      return this.success(state)
    } catch (popupSignOutError) {
      try {
        await this.userManager.signoutRedirect(this.createArguments(state))
        return this.redirect()
      } catch (redirectSignOutError) {
        return this.error(redirectSignOutError)
      }
    }
  }

  async completeSignOut(url) {
    await this.ensureUserManagerInitialized()
    try {
      const response = await this.userManager.signoutCallback(url)
      this.updateState(null)
      return this.success(response && response.data)
    } catch (error) {
      // console.log(`There was an error trying to log out '${error}'.`);
      return this.error(error)
    }
  }

  updateState(user) {
    if (user) {
      useJwt.setToken(user.access_token)
      useJwt.setRefreshToken(user.refresh_token)
      sessionStorage.setItem('userData', JSON.stringify(user))
    }
    this._user = user
    this._isAuthenticated = !!this._user
    this.notifySubscribers()
  }

  subscribe(callback) {
    this._callbacks.push({
      callback,
      subscription: this._nextSubscriptionId++,
    })
    return this._nextSubscriptionId - 1
  }

  unsubscribe(subscriptionId) {
    const subscriptionIndex = this._callbacks
      .map((element, index) => (element.subscription === subscriptionId
        ? { found: true, index }
        : { found: false }))
      .filter(element => element.found === true)
    if (subscriptionIndex.length !== 1) {
      throw new Error(
        `Found an invalid number of subscriptions ${subscriptionIndex.length}`,
      )
    }

    this._callbacks.splice(subscriptionIndex[0].index, 1)
  }

  notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
      const { callback } = this._callbacks[i]
      callback()
    }
  }

  createArguments(state) {
    return { useReplaceToNavigate: true, data: state }
  }

  error(message) {
    return { status: AuthenticationResultStatus.Fail, message }
  }

  success(state) {
    return { status: AuthenticationResultStatus.Success, state }
  }

  redirect() {
    return { status: AuthenticationResultStatus.Redirect }
  }

  async ensureUserManagerInitialized() {
    if (this.userManager !== undefined) {
      return
    }

    const hostAndPort = window.location.origin

    const settings = {
      authority: `${getEnvVariable('VUE_APP_IDENTITY_API_URL', process.env.VUE_APP_IDENTITY_API_URL)}`,
      client_id: getEnvVariable('VUE_APP_CLIENT_ID', process.env.VUE_APP_CLIENT_ID),
      redirect_uri: `${hostAndPort}${AuthorizationPaths.LoginCallback}`,
      response_type: getEnvVariable('VUE_APP_RESPONSE_TYPE', process.env.VUE_APP_RESPONSE_TYPE),
      scope: getEnvVariable('VUE_APP_SCOPE', process.env.VUE_APP_SCOPE),
      post_logout_redirect_uri: `${hostAndPort}${AuthorizationPaths.LogOutCallback}`,
      silentRequestTimeoutInSeconds: getEnvVariable('VUE_APP_AUTENTICATION_TIMEOUT_SECONDS', process.env.VUE_APP_AUTENTICATION_TIMEOUT_SECONDS),
    }

    settings.automaticSilentRenew = true
    settings.includeIdTokenInSilentRenew = true
    settings.userStore = new WebStorageStateStore({
      prefix: ApplicationName,
    })

    this.userManager = new UserManager(settings)

    this.userManager.events.addUserSignedOut(async () => {
      await this.userManager.removeUser()
      this.updateState(undefined)
    })
  }

  static get instance() {
    return authService
  }
}

const authService = new AuthorizeService()

export default authService

export const AuthenticationResultStatus = {
  Redirect: 'redirect',
  Success: 'success',
  Fail: 'fail',
}
