import axiosIdentity from "@utils/axiosIdentity";

const transformData = (data) => {
  return {
    uniqueApplications: [{
      applicationId: data.id,
      applicationName: data.applicationName,
      assignedUsersCount: data.userCount,
      applicationMemberships: data.applicationMemberships.length,
      maxUsersAllowed: data.maxUser,
      licenseType: data.licenseType,
      availableRoles: data.roles.map((rol) => ({
        roleId: rol.id,
        name: rol.name,
      }))
    }],
    users: data.applicationMemberships.map((membership) => {
      const user = membership.applicationUser;
      return {
        userName: user.userFullName.trim(),
        id: user.id,
        memberships: user.userRoles.map((role) => ({
          applicationId: data.id,
          membershipidId: user.id,
          roleId: role.id,
          roleName: role.name,
        })),
      };
    }),
  };
}

const actions = {
  async assingUser(_, { id, data }) {
    try {
      const response = await axiosIdentity.post(
        `/v1/scisa_application/${id}/assing_user`,
        data,
        { headers: { "Content-Type": "application/json" } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async removeUserMembership(_, { id, data }) {
    try {
      const response = await axiosIdentity.post(
        `/v1/scisa_application/${id}/remove_user_membership`,
        data,
        { headers: { "Content-Type": "application/json" } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getApplication(_, { id }) {
    try {
      const response = await axiosIdentity.get(
        `/v1/scisa_application/${id}`,
        { headers: { "Content-Type": "application/json" } }
      );
      return transformData(response.data);
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  actions,
};
