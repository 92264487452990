import { defineAsyncComponent } from 'vue';

export default [
  {
    path: '/',
    name: 'home',
    component: defineAsyncComponent(() => import('@/views/home/UserAccess.vue')),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/usuarios',
    name: 'users',
    component: defineAsyncComponent(() => import('@/views/users/Users.vue')),
  },
  {
    path: '/crear-usuario',
    name: 'createUser',
    component: defineAsyncComponent(() => import('@/views/users/CreateUser.vue')),
  },
  {
    path: '/usuario/:id?',
    // name: 'userProfile',
    component: defineAsyncComponent(() => import('@/views/users/User.vue')),
    meta: {
      pageTitle: 'Usuarios',
      fromIcon: 'user',
      fromRoute: 'users',
      breadcrumb: [
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
    children: [
      {
        path: 'general',
        name: 'userGeneralInfo',
        component: defineAsyncComponent(() => import('@/components/users/UserGeneralInfo.vue')),
      },
      {
        path: 'cambiar-contrasena',
        name: 'userChangePassword',
        component: defineAsyncComponent(() => import('@/components/users/UserChangePassword.vue')),
      },
      {
        path: 'roles',
        name: 'userRolesSettings',
        component: defineAsyncComponent(() => import('@/components/users/UserRolesSettings.vue')),
      },
      {
        path: 'permisos',
        name: 'userPermissionsSettings',
        component: defineAsyncComponent(() => import('@/components/users/UserPermissionsSettings.vue')),
      },
    ],
  },
  {
    path: '/roles',
    name: 'roles',
    props: true,
    component: defineAsyncComponent(() => import('@/views/roles/Roles.vue')),
  },
  {
    path: '/aplicaciones',
    name: 'apps',
    component: defineAsyncComponent(() => import('@/views/apps/Apps.vue')),
  },
  {
    path: '/seguridad-informatica',
    name: 'informaticSecurity',
    component: defineAsyncComponent(() => import('@/views/params/InformaticSecurity.vue')),
  },
  {
    path: '/configuracion-de-aplicacion',
    name: 'appSettings',
    component: defineAsyncComponent(() => import('@/views/params/AppsSettings.vue')),
  },
  {
    path: '/administracion-de-aplicacion',
    name: 'appAdmin',
    component: defineAsyncComponent(() => import('@/views/apps/AppAdmin.vue')),
  },
  {
    path: '/sessions',
    name: 'sessions',
    component: defineAsyncComponent(() => import('@/views/sessions/Sessions.vue')),
  },
  {
    path: '/sessionsv2',
    name: 'sessionsv2',
    component: defineAsyncComponent(() => import('@/views/sessions/SessionsV2.vue')),
  },
  {
    path: '/scopes/:id',
    name: 'ScopesForm',
    component: defineAsyncComponent(() => import('@/views/scopes/scopeForm.vue')),
  },
  {
    path: '/scopes',
    name: 'ScopesAdmin',
    component: defineAsyncComponent(() => import('@/views/scopes/Scopes.vue')),
  },
  {
    path: '/applications',
    name: 'Applications',
    component: defineAsyncComponent(() => import('@/views/applications/Applications.vue')),
  },
  {
    path: '/applications/:id',
    name: 'ApplicationDetails',
    meta: {
      navActiveLink: 'Applications'
    },
    component: defineAsyncComponent(() => import('@/views/applications/Application.vue')),
  },
  {
    path: '/webhook/events',
    name: 'Events',
    component: defineAsyncComponent(() => import('@/views/webhooks/Events.vue')),
  },
  {
    path: '/webhooks',
    name: 'WebHook',
    component: defineAsyncComponent(() => import('@/views/webhooks/Webhooks.vue')),
  },
  {
    path: '/webhooks/:id',
    name: 'WebhookDetails',
    component: defineAsyncComponent(() => import('@/views/webhooks/Webhook.vue')),
  },
  {
    path: '/license-management',
    name: 'LicenseManagement',
    component: defineAsyncComponent(() => import('@/views/licences/LicenseManagement.vue')),
  },
];
