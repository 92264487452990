import AxiosIdentity from "@/utils/axiosIdentity";
import AxiosInstance from "@/utils/axios";

const withLoading = async (context, apiCall) => {
  context.commit("SET_LOADING", true);
  try {
    const response = await apiCall();
    return response.data;
  } catch (error) {
    throw error;
  } finally {
    context.commit("SET_LOADING", false);
  }
};

export default {
  namespaced: true,
  state: {
    isLoading: false,
    session: [],
    sessionV2: [],
  },
  getters: {
    sessions(state) {
      return state.session;
    },
  },
  mutations: {
    SET_SESSION_ITEMS(state, payload) {
      state.session = payload;
    },
    SET_SESSION_V2_ITEMS(state, payload) {
      state.sessionV2 = payload;
    },
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    getSessionsV1(context, { id }) {
      return withLoading(context, async () => {
        const response = await AxiosInstance.get(`/sessions/${id}`);
        context.commit("SET_SESSION_ITEMS", response.data);
        return response;
      });
    },

    closeSessionV1(context, { id }) {
      return withLoading(context, async () => {
        const response = await AxiosInstance.delete(`/sessions/${id}`);
        return response;
      });
    },

    closeAllSessionsV1(context, { id }) {
      return withLoading(context, async () => {
        const response = await AxiosInstance.delete(
          `/sessions/remove-all-sessions/${id}`
        );
        return response;
      });
    },

    getAllSessionsV2(context, { id }) {
      return withLoading(context, async () => {
        const response = await AxiosIdentity.get(`/v1/sessions/${id}`, {
          headers: { "Content-Type": "application/json" },
        });
        context.commit("SET_SESSION_V2_ITEMS", response.data);
        return response;
      });
    },

    closeSessionV2(context, { authorizationId }) {
      return withLoading(context, async () => {
        const response = await AxiosIdentity.post(
          `/v1/sessions/${authorizationId}/revoke`
        );
        return response;
      });
    },

    closeAllSessionsV2(context) {
      return withLoading(context, async () => {
        const response = await AxiosIdentity.post(`/v1/sessions/revoke_all`);
        return response;
      });
    },
  },
};
