<template>
  <div v-if="!loading" id="app" class="h-100" :class="[skinClasses]">
    <!-- {{ showModal }}
    <b-button @click="openInactivityModal">
      button
    </b-button> -->
    <Vidle
      v-show="false"
      v-if="isUserLoggedIn()"
      :duration="3600"
      :reminders="[60]"
      @idle="logout"
      @remind="openInactivityModal"
    />
    <b-modal
      id="inactivityModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      v-model="showModal"
    >
      <div>
        <div>{{ $t("sessionExpiring") }}</div>
        <span class="text-primary">{{ timer }}</span>
      </div>
      <template #modal-footer="{ ok, hide }">
        <b-button
          size="sm"
          variant="outline-secondary"
          @click="
            () => {
              hide();
              logout();
            }
          "
        >
          {{ $t("logout") }}
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          @click="
            () => {
              ok();
              cancelIdle();
            }
          "
        >
          {{ $t("keepSesion") }}
        </b-button>
      </template>
    </b-modal>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { onMounted, watch, ref, onBeforeMount, provide } from "vue";
import { BModal } from "bootstrap-vue-next";
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import useAppConfig from "@core/app-config/useAppConfig";
import { isUserLoggedIn } from "@/auth/utils";
import { useWindowSize, useCssVar } from "@vueuse/core";
import useJwt from "@/auth/jwt/useJwt";
import authService from "@/utils/authorize-service";
import store from "@/store";
import { useToast } from "vue-toastification";
import Vidle from "v-idle";
import { defineAsyncComponent } from "vue";

const LayoutVertical = defineAsyncComponent(() =>
  import("@/layouts/vertical/LayoutVertical.vue")
);

const LayoutFull = defineAsyncComponent(() =>
  import("@/layouts/full/LayoutFull.vue")
);

import { useRoute } from "vue-router";
import { computed } from "vue";

export default {
  components: {
    // Layouts
    LayoutVertical,
    LayoutFull,
    Vidle,
    BModal,
  },
  setup() {
    provide("store", store);

    const toast = useToast();
    provide("toast", toast);

    const loading = ref(false);
    const inactivityModal = ref(null);

    const { skin, skinClasses } = useAppConfig();
    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    const route = useRoute();

    const contentLayoutType = computed(() => store.state.appConfig.layout.type);

    const layout = computed(() => {
      if (route.meta.layout === "full") return "LayoutFull";
      return `Layout${
        contentLayoutType.value.charAt(0).toUpperCase() +
        contentLayoutType.value.slice(1)
      }`;
    });

    const logout = async () => {
      // Remove userData from sessionStorage
      // ? You just removed token from sessionStorage. If you like, you can also make API call to backend to blacklist used token
      sessionStorage.clear();
      sessionStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      sessionStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from sessionStorage
      store.commit("auth/CLEAR_CURRENT_USER");
      sessionStorage.removeItem("userData");

      await authService.signOut();
    };

    onBeforeMount(() => {
      // Set colors in theme
      const colors = [
        "primary",
        "secondary",
        "success",
        "info",
        "warning",
        "danger",
        "light",
        "dark",
      ];

      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = colors.length; i < len; i++) {
        $themeColors[colors[i]] = useCssVar(
          `--${colors[i]}`,
          document.documentElement
        ).value.trim();
      }

      // Set Theme Breakpoints
      const breakpoints = ["xs", "sm", "md", "lg", "xl"];

      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = breakpoints.length; i < len; i++) {
        $themeBreakpoints[breakpoints[i]] = Number(
          useCssVar(
            `--breakpoint-${breakpoints[i]}`,
            document.documentElement
          ).value.slice(0, -2)
        );
      }

      // Set RTL
      const { isRTL } = $themeConfig.layout;
      document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
    });

    const showModal = ref(false);
    const isModalOpen = ref(false);
    const timer = ref(0);
    const startTimer = ref(false);

    const openInactivityModal = (time) => {
      isModalOpen.value = true;
      showModal.value = true;
      time.value = time;
      startTimer.value = true;
    };

    const cancelIdle = () => {
      showModal.value = false;
      startTimer.value = false;
      isModalOpen.value = false;
      timer.value = 0;
    };

    return {
      skinClasses,
      isUserLoggedIn,
      layout,
      logout,
      inactivityModal,
      openInactivityModal,
      showModal,
      cancelIdle,
    };
  },
};
</script>

<style>
#activityCanvas {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999999;
}
</style>
