import api from "@/utils/axios";
import i18n from "@/libs/i18n";

const state = () => ({
  roles: [],
  isLoading: false,
});

const getters = {
  roles(state) {
    return state.roles;
  },
};

const mutations = {
  ADD_ROLE(state, payload) {
    state.roles.push(payload);
  },
  SET_ROLES(state, payload) {
    state.roles = payload;
  },
  SAVE_USER_ROLE(state, roleId) {
    state.roles.forEach((role) => {
      if (role.role.id === roleId) {
        role.isActive = true;
      } else {
        role.isActive = false;
      }
    });
  },
  SET_LOADNIG(state, value) {
    state.isLoading = value;
  },
};

const actions = {
  getRoles(context, { applicationId }) {
    context.commit("SET_LOADNIG", true);
    return new Promise((resolve, reject) => {
      api
        .get("/roles", {
          params: {
            ApplicationId: applicationId,
          },
        })
        .then((response) => {
          context.commit("SET_ROLES", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit("SET_LOADNIG", false);
        });
    });
  },

  createRole(context, { applicationId, roleName }) {
    return new Promise((resolve, reject) => {
      api
        .post("/roles", {
          ApplicationId: applicationId,
          Name: roleName,
        })
        .then((response) => {
          context.commit("ADD_ROLE", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getUserRoles(context, { userId, applicationId }) {
    return new Promise((resolve, reject) => {
      api
        .get(`/users/${userId}/user_roles`, {
          params: {
            applicationId,
          },
        })
        .then((response) => {
          context.commit("SET_ROLES", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  saveUserRoles(context, { userId, applicationId, roleId }) {
    return new Promise((resolve, reject) => {
      api
        .post("/users/save_user_role", {
          applicationId,
          roleId,
          userId,
        })
        .then((response) => {
          context.commit("SAVE_USER_ROLE", roleId);
          this._vm.$toast.success(i18n.t("successMessages.roleUpdated"));
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
