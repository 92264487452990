import { createApp } from "vue";
import { i18n } from "@/libs/i18n";
import { createBootstrap } from "bootstrap-vue-next";
import { options } from "./toastOptions";
import tooltipDirective from "@/directives/tooltip";

import router from "./router";
import store from "./store";
import App from "./App.vue";
// import './filters'

import VueFeather from "vue-feather";

// Add the necessary CSS
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

// 3rd party plugins
import "@axios";

// import '@/libs/toastification'
import Toast from "vue-toastification";

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

// vue-select
import { VueSelect } from "vue-select";
import "vue-select/dist/vue-select.css";

import registerGlobalComponents from "@/global-components";

const app = createApp(App);

app.config.errorHandler = (err, instance, info) => {
};

app.component("FeatherIcon", VueFeather);

app.use(i18n);
app.use(Toast, options);
// Global Components
registerGlobalComponents(app);
app.component("v-select", VueSelect);

app.use(store);
app.use(router);
app.use(createBootstrap());

app.directive("tooltip", tooltipDirective);

app.mount("#app");
