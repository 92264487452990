import axiosIdentity from '@utils/axiosIdentity'

export default {
  namespaced: true,
  state: {
    applications: {
      results: [],
    },
  },
  getters: {
    getApps(state) {
      return state.applications
    },
  },
  mutations: {},
  actions: {
    getApplications(_, { perPage, currentPage }) {
      return new Promise((resolve, reject) => {
        axiosIdentity
          .get('/v1/applications',{
          params: {
            PerPage: perPage,
            CurrentPage: currentPage,
          },
        })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getApplicationsById(_, { id }) {
      return new Promise((resolve, reject) => {
        axiosIdentity
          .get(`/v1/applications/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    generateSecret(_, { id }) {
      return new Promise((resolve, reject) => {
        axiosIdentity
          .put(`/v1/applications/${id}/regenerate_secret`,{
            headers: { 'Content-Type': 'application/json' },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateApplication(_, { id, application }) {
      return new Promise((resolve, reject) => {
        axiosIdentity
          .put(`/v1/applications/${id}`, application, {
            headers: { 'Content-Type': 'application/json' },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    deleteApplication(_, { id }) {
      return new Promise((resolve, reject) => {
        axiosIdentity
          .delete(`/v1/applications/${id}`, {
            headers: { 'Content-Type': 'application/json' }
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    uploadApplication(_, { data }) {
      return new Promise((resolve, reject) => {
        axiosIdentity
          .post('/v1/applications', data, {
            headers: { 'Content-Type': 'application/json' }
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
