import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import { createRouter, createWebHistory } from 'vue-router';

// Routes
import pages from './routes/pages'
import scisaViews from './routes/scisaViews'

const routes = [
  { path: '/', redirect: { name: 'home' } },
  ...pages,
  ...scisaViews,
  {
    path: '/:pathMatch(.*)*',
    redirect: 'error-404',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    return next(getHomeRouteForLoggedInUser(userData ? userData.profile : null))
  }

  if (to.meta.requiresAuth && !isLoggedIn) {
    return next('/login')
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
